import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
import BlogCard from "../../components/_ui/BlogCard.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <section className="breakout">
  <div className="container island--large flow--small">
    <h1 className="text-page-title">Outdoor living</h1>
    <p className="text-paragraph text-color--steelbook">
      How to get the most out of your Pergolas, Arbours, &amp; Plants
    </p>
  </div>
  <div className="container">
    <div className="grid-x grid-margin-x grid-margin-y">
      <div className="cell medium-6 large-4">
        <BlogCard title="Pergola Planting: Annual Climbers in Pots" postedAt="3rd August 2019" imgSrc="/img/outdoor-living/pergola-planting-annual-climbers-in-pots/cupandsaucervine-rhs.jpg" imgAlt="The purple flowers of a cup-and-saucer vine (cobaea scandens AGM)" url="/news/pergola-planting-annual-climbers-in-pots/" mdxType="BlogCard" />
      </div>
      <div className="cell medium-6 large-4">
        <BlogCard title="Karen’s Spring Blog - Edible Hanging Baskets" postedAt="15th August 2019" url="/news/karens-spring-blog-edible-hanging-baskets/" imgSrc="/img/outdoor-living/edible-hanging-baskets/tomato-hanging-basket.jpg" mdxType="BlogCard" />
      </div>
      <div className="cell medium-6 large-4">
        <BlogCard title="Scented Climbers for Pergolas" postedAt="15th May 2019" url="/news/scented-climbers-for-pergolas" imgSrc="/img/outdoor-living/scented-climbers-for-pergolas/trachelospermum.jpg" mdxType="BlogCard" />
      </div>
      <div className="cell medium-6 large-4">
        <BlogCard title="How to revamp wooden outdoor furniture" postedAt="5th January 2019" imgSrc="/img/outdoor-living/how-to-revamp-wooden-outdoor-furniture/Cuprinol_(8).jpg" imgAlt="An outdoor seating area underneath a pergola, with an array of wicker garden seating and a barbeque" url="/news/how-to-revamp-wooden-outdoor-furniture/" mdxType="BlogCard" />
      </div>
      <div className="cell medium-6 large-4">
        <BlogCard title="Pergola Planting: Climbing Vegetables" postedAt="4th February 2019" url="/news/pergola-planting-climbing-vegetables/" imgSrc="/img/outdoor-living/pergola-planting-climbing-vegetables/Runner-bean-pods-RHS.jpg" imgAlt="A group of runner bean pods ready for picking" mdxType="BlogCard" />
      </div>
      <div className="cell medium-6 large-4">
        <BlogCard title="5 ways to embrace the Mediterranean lifestyle in your back garden" postedAt="3rd March 2010" url="/news/5-ways-to-embrace-the-mediterranean-lifestyle-in-your-back-garden/" imgSrc="/img/outdoor-living/5-ways-to-embrace-the-mediterranean-lifestyle-in-your-back-garden/download.jpg" imgAlt="A long outdoor dining table set with a lunch spread for six places" mdxType="BlogCard" />
      </div>
    </div>
  </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      